import React from "react";

import { ModalWithFooter } from "./ModalWithFooter";

interface IDeleteConfirmationModalProps {
  onDelete?: () => void;
  onCancel?: () => void;
  header: string | React.ReactNode;
  disableDelete?: boolean;
  disableCancel?: boolean;
  disableAll?: boolean;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const DeleteConfirmationModal = ({
  onClose,
  isOpen,
  header,
  onCancel,
  onDelete,
  disableCancel,
  disableDelete,
  disableAll,
  children,
}: IDeleteConfirmationModalProps) => {
  return (
    <ModalWithFooter
      header={header}
      isOpen={isOpen}
      onClose={onClose}
      onClickPrimary={onDelete}
      primaryText="Delete"
      secondaryText="Cancel"
      disablePrimary={disableDelete}
      disableSecondary={disableCancel}
      disableAll={disableAll}
      onClickSecondary={onCancel ? onCancel : onClose}
    >
      {children}
    </ModalWithFooter>
  );
};

export default DeleteConfirmationModal;
