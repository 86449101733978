import { Spinner, Stack } from "@fluentui/react";
import { useState } from "react";
import { ErrorBar, StyledDefaultButton, StyledPrimaryButton } from "src/components";
import { useIsMobileView } from "src/hooks";
import { ConvincedText } from "./ConvincedText";
import { PreferenceList } from "./PreferenceList";

export interface IEditPreferences {
  onBack: () => void;
  companyLoading: boolean;
  userLoading: boolean;
  updateError: unknown;
  companyError: unknown;
  userError: unknown;
  companyInterests?: SimpleCompanyInterest[];
  userData?: WatercoolerUser;
  currentPreference: WatercoolerUserPreference;
  isUpdating: boolean;
  onUpdate: (newPreference: WatercoolerUserPreference) => void;
}

export const EditPreferences = ({
  onBack,
  companyError,
  companyLoading,
  updateError,
  userError,
  userLoading,
  companyInterests,
  userData,
  currentPreference,
  isUpdating,
  onUpdate,
}: IEditPreferences) => {
  const loading = companyLoading || userLoading;
  const error = updateError || companyError || userError;
  const showData = companyInterests !== undefined && userData !== undefined;

  const [preference, setPreference] = useState<WatercoolerUserPreference>(currentPreference);
  const isMobileView = useIsMobileView();

  return (
    <>
      <ErrorBar error={error} />
      <ConvincedText />
      {loading && (
        <div className="flex items-center w-full h-full justify-center">
          <Spinner label="Loading preferences" />
        </div>
      )}
      {!loading && showData && preference && (
        <PreferenceList
          interestDictionary={companyInterests ?? {}}
          userPreference={preference}
          onModifyPreference={setPreference}
          isBusy={isUpdating}
        />
      )}
      <Stack
        horizontal
        horizontalAlign={isMobileView ? "center" : "end"}
        className="w-full px-8 pb-6 gap-2 pt-8 md:pt-0"
      >
        <StyledDefaultButton disabled={isUpdating} onClick={onBack} text="Back" />
        <StyledPrimaryButton
          disabled={isUpdating || !!error}
          primary
          onClick={() => onUpdate(preference)}
          text={"Submit"}
        />
      </Stack>
    </>
  );
};
