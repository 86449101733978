import { Stack } from "@fluentui/react";
import React from "react";

import { StyledPrimaryButton } from "src/components/Themed";

import { Interests } from "./Interests";

export interface IPreferencesProps {
  onEdit: () => void;
  topics: Record<string, string>;
  isLoading: boolean;
}

export const Preferences = ({ onEdit, topics, isLoading }: IPreferencesProps) => {
  const interests = Object.values(topics);

  if (isLoading) return <></>;

  return (
    <>
      <Interests interests={interests} />
      <Stack horizontal horizontalAlign="center" className="w-full px-8 pb-6">
        <StyledPrimaryButton onClick={onEdit} text=" Change my preference" />
      </Stack>
    </>
  );
};
