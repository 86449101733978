import { IColumn, IconButton, Link, MessageBar, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";

import { StyledText } from "src/components";

export interface IPreviousTopicsState {
  previousTopicsLoading?: Record<string, boolean>;
  previousTopics?: Record<string, SimpleCompanyTopic[]>;
  previousTopicsExpanded?: Record<string, boolean>;
}

interface IGetColumns {
  goToInterestPage: (item: SimpleCompanyInterest) => void;
  isBusy: boolean;
  onDeleteInterest: (item: SimpleCompanyInterest) => void;
  onAddInterest: (item: SimpleCompanyInterest) => void;
  onToggleExpandInterest: (
    intrestId: string,
    expand: boolean,
    previousTopicsState?: IPreviousTopicsState
  ) => Promise<IPreviousTopicsState>;
  loadingTopics: Record<string, boolean>;
  topics: Record<string, SimpleCompanyTopic[]>;
  expandedInterest: Record<string, boolean>;
  interestIds: string[];
}

const nestedTopicColumn: IColumn = {
  key: "default",
  name: "",
  minWidth: 200,
  isMultiline: true,
  onRender: (item?: SimpleCompanyTopic) => {
    if (!item) return;

    return <StyledText>{item.name} </StyledText>;
  },
};

export const getColumns = (props: IGetColumns): IColumn[] => {
  const {
    expandedInterest,
    goToInterestPage,
    isBusy,
    loadingTopics,
    onAddInterest,
    onDeleteInterest,
    onToggleExpandInterest,
    topics,
  } = props;

  return [
    {
      key: "name",
      name: "Name",
      minWidth: 100,
      isResizable: true,
      onRenderHeader: (props, defaultRender) => {
        if (!props || !defaultRender) return null;

        return <div className="pl-5">{defaultRender(props)}</div>;
      },
      isMultiline: true,
      onRender: (item?: SimpleCompanyInterest) => {
        if (!item) return;

        const isExpanded = expandedInterest[item.id];
        const topicsForThisInterest = topics[item.id] ?? [];
        const topicsLoadingForThisInterest = loadingTopics[item.id] ?? false;

        return (
          <>
            <StyledText>{item.name}</StyledText>
            {isExpanded && (
              <ShimmeredDetailsList
                columns={[nestedTopicColumn]}
                enableShimmer={topicsLoadingForThisInterest}
                shimmerLines={3}
                items={topicsForThisInterest}
                isHeaderVisible={false}
                selectionMode={SelectionMode.none}
              />
            )}
            {isExpanded && !topicsLoadingForThisInterest && topicsForThisInterest.length === 0 && (
              <MessageBar>No topics found for Interest</MessageBar>
            )}
          </>
        );
      },
    },
    {
      key: "Actions",
      minWidth: 70,
      name: "Actions",
      isResizable: true,
      onRender: (item?: SimpleCompanyInterest) => {
        if (!item) return;

        return (
          <div>
            <IconButton disabled={isBusy} iconProps={{ iconName: "Delete" }} onClick={() => onDeleteInterest(item)} />
          </div>
        );
      },
    },
  ];
};
