import { BackgroundShade2, StyledText } from "src/components";
import { useIsMobileView } from "src/hooks";
import { MeetingSettings } from "./meeting";

export const AdminSettingsPage = () => {
  const isMobileView = useIsMobileView();

  return (
    <BackgroundShade2
      className="md:px-8 md:py-6 md:m-8 gap-8 flex flex-col px-3"
      style={{ width: isMobileView ? "100%" : undefined }}
    >
      <StyledText variant="xLarge">Company settings</StyledText>
      <div className="mx-0 my-auto grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-1">
        <MeetingSettings></MeetingSettings>
      </div>
    </BackgroundShade2>
  );
};
