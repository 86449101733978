import { Stack } from "@fluentui/react";
import React from "react";

import { BackgroundShade2, StyledPrimaryButton, StyledText } from "src/components";

import { getHeaderText } from "./Interests";
import { IPreferencesProps } from "./Preferences";

export const PreferencesMobile = ({ onEdit, topics, isLoading }: IPreferencesProps) => {
  const interests = Object.values(topics);

  if (isLoading) return <></>;

  return (
    <div className="flex flex-col gap-6">
      <BackgroundShade2 className="p-3  border-[1px] border-[#D1D1D1] rounded-[4px] flex flex-col gap-5">
        <StyledText skipDark>{getHeaderText(interests.length)}</StyledText>

        <div className="font-bold flex flex-col gap-3">
          {interests.map((item) => (
            <StyledText key={item} skipDark>
              <b>{item}</b>
            </StyledText>
          ))}
        </div>
      </BackgroundShade2>
      <Stack horizontal horizontalAlign="center" className="w-full px-8 pb-6">
        <StyledPrimaryButton onClick={onEdit} text=" Change my preference" />
      </Stack>
    </div>
  );
};
