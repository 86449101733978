import { getAccessTokenHeader } from "../../util";
import { msGraphClient } from "./client";

export interface ProfileInfo {
  displayName: string;
  givenName: string;
  mail: string;
  surname: string;
  userPrincipalName: string;
  id: string;
}

export const getMe = async (accessToken: string): Promise<ProfileInfo> => {
  const res = await msGraphClient.get(`/me`, {
    headers: getAccessTokenHeader(accessToken),
  });

  return res.data;
};

// https://learn.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
export const getProfilePic = async (
  accessToken: string,
  userId: string,
  size: 48 | 64 | 96 | 120 | 240 | 360 | 432 | 504 | 648
): Promise<any> => {
  const res = await msGraphClient.get(`/users/${userId}/photos/${size}x${size}/$value/`, {
    headers: getAccessTokenHeader(accessToken),
    responseType: "blob",
  });

  return res.data;
};
