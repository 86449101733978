import { ITextProps, Text } from "@fluentui/react";
import React from "react";

import clsx from "clsx";
import { useThemePallete } from "src/context";

interface IStyledText extends ITextProps {
  skipDark?: boolean;
}

export const StyledText = (props: IStyledText) => {
  const { white } = useThemePallete();
  return <Text variant="large" {...props} className={clsx(!props.skipDark && `text-[#${white}]`, props.className)} />;
};

export default StyledText;
