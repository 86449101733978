import React from "react";

import { BackgroundShade2, WhiteBackground } from "src/components/Themed";
import { useIsMobileView } from "src/hooks";

export const Card = ({ children }: { children: React.ReactNode }) => {
  const isMobileView = useIsMobileView();

  if (isMobileView)
    return (
      <BackgroundShade2 className="rounded-[4px] h-fit px-8 py-6" style={{ border: "1px solid rgba(0, 0, 0, 0.07)" }}>
        {children}
      </BackgroundShade2>
    );

  return (
    <WhiteBackground className="rounded-[4px] h-fit px-8 py-6" style={{ border: "1px solid rgba(0, 0, 0, 0.07)" }}>
      {children}
    </WhiteBackground>
  );
};

export * from "./GraphCard";
