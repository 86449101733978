import { Spinner } from "@fluentui/react";
import React from "react";

export const AdminPage = () => {
  return (
    <>
      <Spinner />
    </>
  );
};

export * from "./Interests";
export * from "./Dashboard";
export * from "./Event";
export * from "./Settings";
export * from "./AdminHeader";
