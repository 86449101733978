import { getAccessTokenHeader } from "../../util";
import { watercoolerClient } from "./client";

export const apiGetCompanies = async (accessToken: string): Promise<WatercoolerCompany[]> => {
  const res = await watercoolerClient.get("/company", {
    headers: getAccessTokenHeader(accessToken),
  });
  return res.data;
};

export const apiGetUser = async (accessToken: string): Promise<WatercoolerUser> => {
  const res = await watercoolerClient.get(`/user`, {
    headers: getAccessTokenHeader(accessToken),
  });

  return res.data;
};

export const apiGetInterests = async (accessToken: string): Promise<SimpleCompanyInterest[]> => {
  const res = await watercoolerClient.get(`/interest`, {
    headers: getAccessTokenHeader(accessToken),
  });
  return res.data;
};

export const apiGetInterestTopics = async (accessToken: string): Promise<Record<string, SimpleCompanyTopic[]>> => {
  const res = await watercoolerClient.get(`/interest`, {
    headers: getAccessTokenHeader(accessToken),
  });
  return res.data;
};

export const apiUpdatePreference = async (
  preference: WatercoolerUserPreference,
  accessToken: string
): Promise<WatercoolerUserPreference> => {
  const res = await watercoolerClient.post(
    `/user/update-preference`,
    {
      ...preference,
    },
    {
      headers: getAccessTokenHeader(accessToken),
    }
  );
  return res.data;
};
