import { Checkbox } from "@fluentui/react";
import React from "react";

import { StyledText } from "src/components";
// import { MAX_INTERESTS } from "src/constants";
import { useThemePallete } from "src/context";

interface ISingleInterestProps {
  interests: SimpleCompanyInterest[];
  onCheck: (checked: boolean, id: string) => void;
  selectedInterests: string[];
  isBusy?: boolean;
}

export const SingleInterest = ({ interests, onCheck, selectedInterests, isBusy }: ISingleInterestProps) => {
  const { backgroundColorShade6 } = useThemePallete();

  return (
    <div className="flex flex-col gap-3 w-fit">
      {" "}
      {interests.map((item) => {
        const disableCheckBox = () => {
          return isBusy;

          //if (selectedInterests.length >= MAX_INTERESTS && !selectedInterests.includes(item.id)) return true;
        };

        return (
          <div className="flex flex-col gap-3">
            <Checkbox
              disabled={disableCheckBox()}
              checked={selectedInterests.includes(item.id)}
              label={item.name}
              key={item.id}
              styles={(props) => {
                if (props.disabled) {
                  return {
                    checkbox: {
                      borderColor: backgroundColorShade6,
                    },
                  };
                }

                return {};
              }}
              onRenderLabel={(props) => <StyledText variant="medium">{props?.label}</StyledText>}
              onChange={(_, checked) => {
                onCheck(checked ?? false, item.id);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
