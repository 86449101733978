import { IPersonaProps, Persona } from "@fluentui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { useAuthContext } from "src/context";
import { getProfilePic } from "src/services";

interface IPersonaWithImageProps extends IPersonaProps {
  userId: string;
}

export const PersonaWithImage = (props: IPersonaWithImageProps) => {
  const { accessToken } = useAuthContext();

  const { data: imageData } = useQuery({
    queryKey: [`profilePhotoOf${props.userId}`],
    queryFn: () => {
      if (!accessToken) return;

      return getProfilePic(accessToken, props.userId, 48);
    },
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnReconnect: false,
  });
  const url = window.URL || window.webkitURL;
  const blobUrl = imageData ? url.createObjectURL(imageData as any) : undefined;

  return <Persona {...props} imageUrl={blobUrl} />;
};
