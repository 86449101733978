import { getAccessTokenHeader } from "../../util";
import { watercoolerClient } from "./client";

export const apiAddInterest = async (interest: string, accessToken: string): Promise<WatercoolerInterest> => {
  const res = await watercoolerClient.post(
    `/interest`,
    {
      name: interest,
    },
    {
      headers: getAccessTokenHeader(accessToken),
    }
  );

  return res.data;
};

export const apiArchiveIterest = async (interestId: string, accessToken: string): Promise<void> => {
  await watercoolerClient.post(
    `/interest/archive`,
    {
      interestId,
    },
    {
      headers: getAccessTokenHeader(accessToken),
    }
  );
};

export const apiGetMeetingSettings = async (accessToken: string): Promise<MeetingSettings> => {
  const res = await watercoolerClient.get(`/company/meetingsettings`, {
    headers: getAccessTokenHeader(accessToken),
  });

  return res.data;
};

export const apiUpdateMeetingSettings = async (
  minAttendees: number,
  maxAttendees: number,
  accessToken: string
): Promise<void> => {
  await watercoolerClient.post(
    `/company/meetingsettings`,
    {
      minAttendees,
      maxAttendees,
    },
    {
      headers: getAccessTokenHeader(accessToken),
    }
  );
};

export const apiAddTopic = async (
  topic: string,
  interestId: string,
  accessToken: string
): Promise<WatercoolerTopic> => {
  const res = await watercoolerClient.post(
    `/topic`,
    {
      name: topic,
      interestId,
    },
    {
      headers: getAccessTokenHeader(accessToken),
    }
  );

  return res.data;
};

export const apiGetTopics = async (interestId: string, accessToken: string): Promise<SimpleCompanyTopic[]> => {
  const res = await watercoolerClient.get(`/topic`, {
    headers: getAccessTokenHeader(accessToken),
    params: {
      interestId,
    },
  });

  return res.data;
};

export const apiArchiveTopic = async (topicId: string, interestId: string, accessToken: string) => {
  await watercoolerClient.post(
    `/topic/archive`,
    {
      topicId,
      interestId,
    },
    {
      headers: getAccessTokenHeader(accessToken),
    }
  );
};
export const apiGetEvents = async (accessToken: string, startDate: Date, endDate: Date): Promise<IEventData[]> => {
  const res = await watercoolerClient.get(`/dashboard/events`, {
    headers: getAccessTokenHeader(accessToken),
    params: {
      fromDateUTC: startDate.toUTCString(),
      toDateUTC: endDate.toUTCString(),
    },
  });
  return res.data;
};

export const apiGetDashboardTopTopics = async (accessToken: string): Promise<TopTopics[]> => {
  const res = await watercoolerClient.get(`/dashboard/top-topics`, {
    headers: getAccessTokenHeader(accessToken),
  });

  return res.data;
};
