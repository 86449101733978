import { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

export const ConfigPage = () => {
  const onSaveHandler = (saveEvent: microsoftTeams.settings.SaveEvent) => {
    saveEvent.notifySuccess();
  };

  useEffect(() => {
    const setSaveHandler = async () => {
      await microsoftTeams.app.initialize();
      microsoftTeams.pages.config.registerOnSaveHandler(onSaveHandler);
      microsoftTeams.app.notifySuccess();
    };

    setSaveHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h3>You can change your settings in preferences page </h3>;
};
