import React from "react";
import { redirect } from "react-router-dom";

import { ROUTES } from "src/routes";

export const NotFoundPage = () => {
  redirect(ROUTES.home);

  return <></>;
};
