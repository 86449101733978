export const emptyUserInfo: WatercoolerUser = {
  from: 0,
  id: "1",
  isSubscribed: false,
  name: "",
  interests: {},
  until: 0,
  isAdmin: false,
  isFirstLogin: false,
  nextMeetingTimespanInDays: 1,
};

export const emptyIneterest: WatercoolerInterest = {
  company: "",
  companyId: "",
  id: "1",
  isArchived: true,
  name: "",
  topics: [],
};

export const deepCopy = <T>(item: T): T => {
  return JSON.parse(JSON.stringify(item));
};

export const isDevMode = () => process.env.NODE_ENV?.includes("dev");

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getEmptyPreference = (userId: string): WatercoolerUserPreference => {
  return {
    from: 9,
    interests: [],
    until: 17,
    userId,
    nextMeetingTimespanInDays: 1,
  };
};

export const emptyMeetingSettings: MeetingSettings = {
  minAttendees: 2,
  maxAttendees: 8,
};
