import { IconButton, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";

import { Card, StyledText } from "src/components";

interface IGraphCardProps {
  title: string;
  defaultDay: number;
  possibleDays: number[];
  onDaySelectedChange: (item: number) => void;
  children: React.ReactNode;
  onViewMore?: () => void;
  loading?: boolean;
  height?: number;
}

export const GraphCard = ({
  title,
  defaultDay,
  possibleDays,
  onDaySelectedChange,
  children,
  onViewMore,
  loading,
  height,
}: IGraphCardProps) => {
  const [days, setDays] = useState<number>(defaultDay);

  useEffect(() => {
    onDaySelectedChange(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <Card>
      <StyledText variant="xLarge">{title}</StyledText>
      <div className="flex  justify-between pt-6">
        {possibleDays.map((item) => (
          <StyledText
            key={item}
            className={`cursor-pointer ${item === days ? "font-bold text-[#5B5FC7]" : ""}`}
            onClick={() => setDays(item)}
          >
            {`${item} days`}
          </StyledText>
        ))}
      </div>
      {loading && (
        <Spinner
          label="Loading data"
          size={SpinnerSize.large}
          styles={{
            root: {
              height: height,
            },
          }}
        />
      )}
      {!loading && (
        <>
          <div className=" flex flex-col py-2" style={{ maxHeight: height ? height * 3 : undefined }}>
            {children}
          </div>
          {onViewMore && (
            <div className="flex gap-2 items-center text-[#5B5FC7] cursor-pointer">
              <StyledText className="underline" onClick={onViewMore}>
                View More
              </StyledText>
              <IconButton
                onClick={onViewMore}
                iconProps={{ iconName: "SkypeArrow" }}
                styles={{
                  root: {
                    transform: "scaleX(-1)",
                    color: "#5B5FC7",
                  },
                }}
              />
            </div>
          )}
        </>
      )}
    </Card>
  );
};
