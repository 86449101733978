import { Slider } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useWatercoolerContext } from "src/context";

export const MeetingSettings = () => {
  const [minAttendees, setMinAttendees] = React.useState(0);
  const [maxAttendees, setMaxAttendees] = React.useState(0);

  const { getMeetingSettings, updateMeetingSettings } = useWatercoolerContext();

  useEffect(() => {
    setValues();
  }, [minAttendees, maxAttendees]);

  const setValues = async () => {
    if (minAttendees === 0 && maxAttendees === 0) {
      let settings = await getMeetingSettings();
      setMinAttendees(settings.minAttendees);
      setMaxAttendees(settings.maxAttendees);
    }
  };

  const onChange = async (value: number, range?: [number, number]) => {
    if (!range) {
      return;
    }
    let min = range[0];
    let max = range[1];
    if (minAttendees !== min) {
      setMinAttendees(min);
      await updateMeetingSettings(min, max);
    }
    if (maxAttendees !== max) {
      setMaxAttendees(max);
      await updateMeetingSettings(min, max);
    }
  };

  return (
    <div>
      <Slider
        ranged
        showValue
        label="Meeting attendees (minimum , maximum)"
        min={2}
        max={8}
        step={1}
        lowerValue={minAttendees}
        value={maxAttendees}
        onChange={onChange}
      />
    </div>
  );
};
