import React from "react";

import { BackgroundShade2 } from "src/components";

export const ConvincedText = () => {
  const child = (
    <>
      {" "}
      <div className="font-bold"> You deserve a 15-minute break!</div>
      <div>Choose interests from the following options:</div>
    </>
  );

  return (
    <BackgroundShade2
      className="p-3 md:p-4 md:m-8 rounded-[4px] flex flex-col md:gap-[2px] gap-5 border-[#D1D1D1] border-[1px] mb-8"
      id="ConvincedText"
    >
      {child}
    </BackgroundShade2>
  );
};
