import { MessageBar, TextField } from "@fluentui/react";
import React, { useState } from "react";

import { ModalWithFooter } from "src/components";

interface ITextFieldInputModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onClickSave: (topicName: string) => Promise<void>;
  disableSave?: boolean;
  disableCancel?: boolean;
  disableAll?: boolean;
  header: string | React.ReactNode;
  getErrorMessage?: (text: string) => string | undefined;
}

export const TextFieldInputModal = ({
  isOpen,
  onCloseModal,
  onClickSave,
  disableCancel,
  disableSave,
  disableAll,
  header,
  getErrorMessage,
}: ITextFieldInputModalProps) => {
  const [textInput, setTextInput] = useState<string>("");

  const onClose = () => {
    setTextInput("");
    onCloseModal();
  };

  const onClickPrimary = async () => {
    await onClickSave(textInput);
    setTextInput("");
  };

  const shouldPrimaryBeDisabled = () => {
    if (getErrorMessage && getErrorMessage(textInput)) return true;

    return disableAll || disableSave || textInput === "";
  };

  return (
    <ModalWithFooter
      header={header}
      isOpen={isOpen}
      onClose={onClose}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClose}
      primaryText="Save"
      secondaryText="Cancel"
      disablePrimary={shouldPrimaryBeDisabled()}
      disableSecondary={disableAll || disableCancel}
    >
      {getErrorMessage && getErrorMessage(textInput) && <MessageBar>{getErrorMessage(textInput)}</MessageBar>}
      <TextField value={textInput} onChange={(_, newvalue) => setTextInput(newvalue ?? "")} />
    </ModalWithFooter>
  );
};
