import { Dropdown, IDropdownOption } from "@fluentui/react";
import React from "react";

import { StyledText } from "src/components";
import { deepCopy } from "src/util";

interface IWorkingHours {
  preference: WatercoolerUserPreference;
  onModifyPreference: (item: WatercoolerUserPreference) => void;
}

const getTodayWithHour = (hour: number) => {
  const output = new Date();
  output.setMilliseconds(0);
  output.setSeconds(0);
  output.setMinutes(0);
  output.setHours(hour);

  return output;
};

const dropdownStyles = {
  callout: {
    height: 300,
  },
};

const timeOptions: IDropdownOption<Date>[] = Array.from({ length: 24 }, (_, index) => index).map((item) => {
  return {
    key: item,
    text: `${item % 12 === 0 ? 12 : item % 12}:00 ${item > 11 ? "PM" : "AM"}`,
    data: getTodayWithHour(item),
  };
});

export const WorkingHours = ({ onModifyPreference, preference }: IWorkingHours) => {
  const onUpdateWorkingHours = (field: "from" | "until", value: number) => {
    const newPreference = deepCopy(preference);
    newPreference[field] = value;
    onModifyPreference(newPreference);
  };

  return (
    <div className="flex flex-col gap-4">
      <StyledText variant="xLarge">Your working hours</StyledText>
      <div className="flex flex-col xl:flex-row justify-between gap-1">
        <Dropdown
          options={timeOptions}
          className="w-full"
          label="From"
          styles={dropdownStyles}
          selectedKey={preference.from}
          onChange={(_, option) => {
            if (!option) return;
            onUpdateWorkingHours("from", +option.key);
          }}
        />
        <Dropdown
          options={timeOptions}
          className="w-full"
          label="To"
          styles={dropdownStyles}
          selectedKey={preference.until}
          onChange={(_, option) => {
            if (!option) return;
            onUpdateWorkingHours("until", +option.key);
          }}
        />
      </div>
    </div>
  );
};
