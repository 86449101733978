import React from "react";

import { TopFiveTopics } from "./TopFiveTopics";

export const AdminDashboardPage = () => {
  return (
    <div className="p-6 grid grid-cols-1 xl:grid-cols-2 gap-6">
      <TopFiveTopics chartHeight={152} />
    </div>
  );
};
