import React from "react";

import { useThemePallete } from "src/context";

import { BackgroundWrapper } from "./BackgroundWrapper";
import { IBackgroundProps } from "./WhiteBackground";

export const BackgroundShade2 = (props: IBackgroundProps) => {
  const { backgroundColorShade2 } = useThemePallete();

  return (
    <BackgroundWrapper {...props} skipAddingColor={props.skipAddingColor} backgroundColor={backgroundColorShade2}>
      {props.children}
    </BackgroundWrapper>
  );
};
