import React from "react";

import { BackgroundShade2, WhiteBackground } from "src/components";
import { useIsMobileView } from "src/hooks";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const isMobileView = useIsMobileView();

  if (isMobileView)
    return (
      <WhiteBackground className="w-full h-full md:pt-8 pt-4 overflow-auto" id="CustomLayout">
        {children}
      </WhiteBackground>
    );

  return (
    <BackgroundShade2 className="w-full h-full overflow-auto" id="CustomLayout">
      {children}
    </BackgroundShade2>
  );
};
