import { DefaultButton, IButtonProps, PrimaryButton } from "@fluentui/react";
import React from "react";

import clsx from "clsx";

export const StyledPrimaryButton = (props: IButtonProps) => {
  return (
    <PrimaryButton {...props} type="notPrimary" className={clsx("rounded-[4px] dark:text-white", props.className)} />
  );
};

export const StyledDefaultButton = (props: IButtonProps) => {
  return (
    <DefaultButton {...props} type="notDefault" className={clsx("rounded-[4px] dark:text-white", props.className)} />
  );
};
