export const ROUTES = {
  admin: {
    interests: "/admin/interests",
    topics: "/admin/topics",
    events: "/admin/events",
    home: "/admin",
    dashboard: "/admin/dashboard",
    settings: "/admin/settings",
  },
  home: "/home",
  preferences: "/preferences",
  test: "/test",
  configtemp: "/config",
};
