import { Checkbox, DetailsList, IColumn, Link, Panel, PersonaSize, SelectionMode } from "@fluentui/react";
import React, { useState } from "react";

import { PersonaWithImage, StyledDefaultButton, StyledPrimaryButton, StyledText } from "src/components";
import { useThemePallete } from "src/context";

export interface IFilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  attendees: IAttendee[];
  onApplyFilter: (userIds: string[]) => void;
  alreadySelectedAttendees: string[];
}

export const FilterPanel = (props: IFilterPanelProps) => {
  const { isOpen, onClose, attendees, onApplyFilter, alreadySelectedAttendees } = props;

  const sortedAttendees = attendees.sort((a, b) => a.attendeeName.localeCompare(b.attendeeName));
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>(alreadySelectedAttendees);

  const { backgroundColorShade2 } = useThemePallete();

  const onClickApply = () => {
    onApplyFilter(selectedAttendees);
    onClose();
  };

  const onClickClearAll = () => {
    setSelectedAttendees([]);
  };

  const checkAttendee = (item: IAttendee, check: boolean) => {
    setSelectedAttendees([
      ...selectedAttendees.filter((id) => id !== item.attendeeId),
      ...(check ? [item.attendeeId] : []),
    ]);
  };

  const column: IColumn = {
    key: "default",
    name: "",
    minWidth: 200,
    onRender: (item?: IAttendee) => {
      if (!item) return;

      return (
        <Checkbox
          key={item.attendeeId}
          onChange={(e, check) => checkAttendee(item, check ?? false)}
          label={item.attendeeName}
          checked={selectedAttendees.includes(item.attendeeId)}
          onRenderLabel={(props) => (
            <PersonaWithImage
              userId={item.attendeeId}
              text={item.attendeeName}
              size={PersonaSize.size24}
              className="pl-3"
            />
          )}
        />
      );
    },
  };

  return (
    <Panel
      isFooterAtBottom
      isOpen={isOpen}
      onDismiss={onClose}
      closeButtonAriaLabel="Close"
      headerText="Filters"
      isLightDismiss
      styles={{
        main: {
          background: backgroundColorShade2,
        },
        commands: {
          background: backgroundColorShade2,
        },
      }}
      onRenderFooterContent={() => {
        return (
          <div className="flex justify-between">
            <div>
              <Link onClick={onClickClearAll}>Clear all ({selectedAttendees.length})</Link>
            </div>
            <div className="flex gap-2">
              <StyledDefaultButton onClick={onClose}>Cancel</StyledDefaultButton>{" "}
              <StyledPrimaryButton onClick={onClickApply}> Apply</StyledPrimaryButton>
            </div>
          </div>
        );
      }}
    >
      <DetailsList
        items={sortedAttendees}
        columns={[column]}
        checkboxVisibility={1}
        selectionMode={SelectionMode.none}
        isHeaderVisible={false}
        selectionPreservedOnEmptyClick
        groupProps={{
          onRenderHeader: (props, defaultRenderer) => {
            if (defaultRenderer)
              return defaultRenderer({
                ...props,

                groups: [
                  {
                    key: "default",
                    name: "Users",
                    startIndex: 0,
                    count: 0,
                  },
                ],
              });

            return <StyledText className="mb-6">Users({selectedAttendees.length})</StyledText>;
          },
        }}
        groups={[
          {
            key: "default",
            name: "Users",
            startIndex: 0,
            count: sortedAttendees.length,
          },
        ]}
      />
    </Panel>
  );
};
