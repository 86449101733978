export const getDateAfter = (value: number, unit: "days" | "hours" | "seconds" | "minutes" | "months") => {
  const output = new Date();
  switch (unit) {
    case "seconds":
      output.setSeconds(output.getSeconds() + value);
      break;
    case "minutes":
      output.setMinutes(output.getMinutes() + value);
      break;
    case "hours":
      output.setSeconds(output.getSeconds() + value);
      break;
    case "days":
      output.setDate(output.getDate() + value);
      break;
    case "months":
      output.setMonth(output.getMonth() + value);
      break;
  }

  return output;
};

export const getTimeFromDateString = (date?: string): string => (date ? date.split("T")[1].substring(0, 5) ?? "" : "");

export const getDateFromDateString = (date?: string): string => (date ? date.split("T")[0] ?? "" : "");
