import { IPalette, ThemeProvider, useTheme } from "@fluentui/react";
import { createContext, useContext, useEffect, useState } from "react";

import { app } from "@microsoft/teams-js";
import clsx from "clsx";
import { generateTheme } from "src/util";

interface ICustomThemeContext {
  themeMode: ThemeMode;
  toggleTheme: () => void;
}

const themeContext: ICustomThemeContext = {
  themeMode: "default",
  toggleTheme: () => {},
};

const ThemeContext = createContext(themeContext);

export const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>("default");

  const init = async () => {
    await app.initialize();
    const context = await app.getContext();
    setThemeMode(getThemeMode(context.app.theme));
    app.registerOnThemeChangeHandler((theme) => {
      setThemeMode(getThemeMode(theme));
    });
  };

  useEffect(() => {
    init();
  }, []);

  const primaryColor = "#5B5FC7";
  const generatedTheme = generateTheme(primaryColor, "#000000", "#FFFFFF");
  const darkGeneratedTheme = generateTheme(primaryColor, "#FFFFFF", "#242424");

  const toggleTheme = () => {
    if (themeMode === "dark") setThemeMode("default");
    else setThemeMode("dark");
  };

  return (
    <ThemeProvider theme={themeMode === "dark" ? darkGeneratedTheme : generatedTheme}>
      <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
        <div className={clsx("w-full h-full", themeMode)} id="ThemeProvider">
          {children}
        </div>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useThemeContext = (): ICustomThemeContext => {
  return useContext(ThemeContext);
};

export const useDarkMode = (): boolean => {
  return useThemeContext().themeMode === "dark";
};

export const useThemePallete = (): IThemePalette => {
  const theme = useTheme();

  return theme.palette as IThemePalette;
};

const getThemeMode = (mode: string): ThemeMode => {
  if (mode === "dark") return "dark";
  if (mode === "dark") return "contrast";

  return "default";
};

export type ThemeMode = "default" | "dark" | "contrast";
export interface IThemePalette extends IPalette {
  primaryColor: string;
  primaryColorShade1: string;
  primaryColorShade2: string;
  primaryColorShade3: string;
  primaryColorShade4: string;
  primaryColorShade5: string;
  primaryColorShade6: string;
  primaryColorShade7: string;
  primaryColorShade8: string;
  backgroundColor: string;
  backgroundColorShade1: string;
  backgroundColorShade2: string;
  backgroundColorShade3: string;
  backgroundColorShade4: string;
  backgroundColorShade5: string;
  backgroundColorShade6: string;
  backgroundColorShade7: string;
  backgroundColorShade8: string;
  foregroundColor: string;
  foregroundColorShade1: string;
  foregroundColorShade2: string;
  foregroundColorShade3: string;
  foregroundColorShade4: string;
  foregroundColorShade5: string;
  foregroundColorShade6: string;
  foregroundColorShade7: string;
  foregroundColorShade8: string;
}
