import { Spinner } from "@fluentui/react";
import React, { useEffect, useState } from "react";

import { ErrorBar, WhiteBackground } from "src/components";
import { useDarkMode, useThemePallete, useWatercoolerContext } from "src/context";
import { useData, useIsMobileView } from "src/hooks";
import { sendErrorToLogInsights } from "src/services";
import { getEmptyPreference } from "src/util";

import { EditPreferences } from "../../components/EditPreferences/EditPreferences";
import { PreferenceHeader } from "./PreferenceHeader";
import { Preferences } from "./Preferences";
import { PreferencesMobile } from "./PreferencesMobile";

export const PreferencesPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const { userInfo, getUserInfo, getInterests, updatePreference } = useWatercoolerContext();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<unknown>();
  const [preference, setPreference] = useState<WatercoolerUserPreference>(getEmptyPreference(userInfo.id));

  const {
    loading: companyLoading,
    error: companyError,
    data: companyData,
  } = useData(() => {
    return getInterests(true);
  });

  const { loading: userLoading, error: userError, data: userData } = useData(getUserInfo);

  const onUpdate = async (newPreference: WatercoolerUserPreference) => {
    if (isUpdating || !newPreference) return;
    try {
      setIsUpdating(true);
      await updatePreference(newPreference);
      setPreference(newPreference);
    } catch (err) {
      sendErrorToLogInsights(err);
      setUpdateError(err);
    } finally {
      setIsUpdating(false);
      setEditing(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (loading) return;
      try {
        setLoading(true);
        await getUserInfo();
      } catch (err) {
        sendErrorToLogInsights(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPreference({
      from: userData?.from ?? 9,
      interests: Object.keys(userData?.interests ?? {}).map((value) => value) ?? [],
      until: userData?.until ?? 17,
      userId: userData?.id ?? "",
      nextMeetingTimespanInDays: userData?.nextMeetingTimespanInDays ?? 1,
    });
  }, [userData, companyData]);

  const onEdit = () => setEditing(true);
  const { backgroundColor, foregroundColorShade2 } = useThemePallete();
  const isDarkMode = useDarkMode();

  const isMobileView = useIsMobileView();

  return (
    <div className="flex w-full items-center h-full flex-col px-4 md:px-5" id="PreferencesPage">
      <WhiteBackground
        className="w-full xl:w-[50%] rounded-xl"
        style={{ backgroundColor: isDarkMode ? foregroundColorShade2 : backgroundColor }}
      >
        <ErrorBar error={error} />
        {!isMobileView && <PreferenceHeader />}
        {loading && (
          <div className="flex items-center justify-center w-full h-full min-h-[400px]">
            <Spinner label="Loading your preferences" />
          </div>
        )}
        {!loading && (
          <>
            {!editing && userInfo && !isMobileView && (
              <Preferences onEdit={onEdit} topics={userInfo?.interests ?? {}} isLoading={loading} />
            )}
            {editing && !isMobileView && (
              <EditPreferences
                onBack={() => setEditing(false)}
                companyError={companyError}
                companyLoading={companyLoading}
                isUpdating={isUpdating}
                onUpdate={onUpdate}
                updateError={updateError}
                userError={userError}
                userLoading={userLoading}
                companyInterests={companyData}
                currentPreference={preference}
                userData={userData}
              />
            )}
          </>
        )}
      </WhiteBackground>
      {!editing && userInfo && isMobileView && (
        <PreferencesMobile onEdit={onEdit} topics={userInfo?.interests ?? {}} isLoading={loading} />
      )}
      {editing && isMobileView && (
        <EditPreferences
          onBack={() => setEditing(false)}
          companyError={companyError}
          companyLoading={companyLoading}
          isUpdating={isUpdating}
          onUpdate={onUpdate}
          updateError={updateError}
          userError={userError}
          userLoading={userLoading}
          companyInterests={companyData}
          currentPreference={preference}
          userData={userData}
        />
      )}
    </div>
  );
};
