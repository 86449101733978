import { DatePicker, DayOfWeek, defaultDatePickerStrings } from "@fluentui/react";
import React from "react";

interface IHeaderDatePickers {
  fromDate: Date;
  setFromDate: (newDate: Date) => void;
  toDate: Date;
  setToDate: (newDate: Date) => void;
  loading?: boolean;
}

export const HeaderDatePickers = ({ fromDate, setFromDate, setToDate, toDate, loading }: IHeaderDatePickers) => {
  return (
    <div className="flex gap-10 flex-row px-3">
      <DatePicker
        disabled={loading}
        label="From"
        value={fromDate}
        isRequired
        styles={{
          root: {
            width: 200,
          },
        }}
        strings={defaultDatePickerStrings}
        onSelectDate={(date) => {
          if (!date) return;
          setFromDate(date);
        }}
        highlightCurrentMonth
        highlightSelectedMonth
        firstDayOfWeek={DayOfWeek.Monday}
      />

      <DatePicker
        disabled={loading}
        label="To"
        value={toDate}
        isRequired
        styles={{
          root: {
            width: 200,
          },
        }}
        strings={defaultDatePickerStrings}
        onSelectDate={(date) => {
          if (!date) return;
          setToDate(date);
        }}
        highlightCurrentMonth
        highlightSelectedMonth
        firstDayOfWeek={DayOfWeek.Monday}
      />
    </div>
  );
};
