import { MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { GENERIC_500_ERROR_MESSAGE } from "src/constants";
import { sendErrorToLogInsights } from "src/services";

interface IErrorBarProps {
  error: unknown;
  type?: MessageBarType;
}

export const ErrorBar = ({ error, type }: IErrorBarProps) => {
  const [errorInState, setErrorInState] = useState<unknown>(error);

  useEffect(() => {
    setErrorInState(error);

    if (error instanceof Error) {
      sendErrorToLogInsights(error);
    }
  }, [error]);
  if (!error) return <></>;
  let message = "Something Went Wrong";
  const err = errorInState as Error;

  if (err?.message) message = err.message;

  if (axios.isAxiosError(error)) message = error.response?.status === 500 ? error.response?.data : GENERIC_500_ERROR_MESSAGE;

  return (
    <>
      {errorInState && (
        <MessageBar
          messageBarType={type ?? MessageBarType.error}
          onDismiss={() => setErrorInState(undefined)}
          styles={{ iconContainer: { alignItems: "center" } }}
        >
          {message}
        </MessageBar>
      )}
    </>
  );
};
