import { IconButton } from "@fluentui/react";
import React, { createContext, useContext, useEffect, useState } from "react";

import { AdminMenu, AppBreadCrumbs, StyledPrimaryButton, WhiteBackground } from "src/components";
import { IThemePalette, useThemeContext, useThemePallete } from "src/context";
import { useData } from "src/hooks";
import { isDevMode } from "src/util";

import { useWatercoolerContext } from "./Watercooler";

export interface IAdminContext {
  isAdmin: boolean;
}

const adminContext: IAdminContext = {
  isAdmin: false,
};

const AdminContext = createContext(adminContext);

export const AdminContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [adminModeEnabled, setAdminModeEnabled] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { data: UserInfo } = useData(async () => getUserInfo());

  const { toggleTheme } = useThemeContext();
  const pallete = useThemePallete();
  const { getUserInfo } = useWatercoolerContext();

  const providerValue: IAdminContext = {
    isAdmin,
  };

  useEffect(() => {
    // setIsAdmin(true);
    setIsAdmin(UserInfo?.isAdmin ?? false);
  }, [UserInfo]);

  const switchJSX = (
    <div className="flex flex-row-reverse md:flex-row w-full ">
      <IconButton
        iconProps={{ iconName: "UserSettings" }}
        className="dark:hover:bg-neutralTertiary scale-150 pb-2"
        onClick={() => setAdminModeEnabled(!adminModeEnabled)}
      />
      {isDevMode() && (
        <div className="w-full justify-center flex">
          <StyledPrimaryButton onClick={toggleTheme}> Dev Mode: Toggle Theme</StyledPrimaryButton>
        </div>
      )}
    </div>
  );

  if (!isAdmin) return <AdminContext.Provider value={providerValue}>{children}</AdminContext.Provider>;

  const closeMenu = () => {
    setAdminModeEnabled(false);
  };

  return (
    <AdminContext.Provider value={providerValue}>
      <div className="w-full h-full flex flex-col" id="AdminProviderWrapper">
        {isAdmin && (
          <div className="border-b-2 dark:border-neutralTertiary p-3 flex gap-2 w-full items-center">
            {switchJSX}
            <div className="">{adminModeEnabled && <AppBreadCrumbs />}</div>
          </div>
        )}
        {isDevMode() && false && (
          <div className="grid gap-3 grid-cols-6">
            {Object.keys(pallete).map((theme) => {
              return (
                <div style={{ background: pallete[theme as keyof IThemePalette] }} className="w-fit h-fit">
                  {theme}:{pallete[theme as keyof IThemePalette]}
                </div>
              );
            })}
          </div>
        )}

        <div className="flex w-full flex-row h-full">
          {adminModeEnabled && (
            <div className="h-full border-r-2 dark:border-neutralTertiary absolute left-0 z-50">
              <WhiteBackground className="h-full">
                <AdminMenu hideMenu={closeMenu} isOpen={adminModeEnabled} />
              </WhiteBackground>
            </div>
          )}
          <div className="w-full h-full" id="AdminProvider">
            {children}
          </div>
        </div>
      </div>
    </AdminContext.Provider>
  );
};

export function useAdminContext(): IAdminContext {
  return useContext(AdminContext);
}
