import { MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

import { useWatercoolerContext } from "src/context";
import { useData, useIsMobileView } from "src/hooks";
import { getRandomColor } from "src/util";

import { GraphCard } from "../../../components/Card";
import { IChartData } from "./SubscriberMeetingFrequency";

const getSelectedDayData = (selectedDay: number, dashboardTopTopics: TopTopics[]) => {
  const selectedData = dashboardTopTopics.find((item) => item.days === selectedDay);

  if (selectedData)
    selectedData.events = selectedData.events.sort((item1, item2) => item1.topic.localeCompare(item2.topic));

  return (
    selectedData ?? {
      days: 0,
      events: [],
    }
  );
};

const colors = ["#5B5FC7", "#C4314B", "#DFDEDE", "#929191", "#464775"];

const defaultDay = 7;

export const TopFiveTopics = ({ chartHeight }: { chartHeight: number }) => {
  const { dashboardTopTopics, getDashboardTopTopics } = useWatercoolerContext();
  const { loading, error: LoadingError } = useData(getDashboardTopTopics);
  const [selectedDay, setSelectedDay] = useState<number>(defaultDay);
  const isMobileView = useIsMobileView();

  const possibleDays = dashboardTopTopics.map((item) => item.days);
  const selectedData = getSelectedDayData(selectedDay, dashboardTopTopics);
  const totalEventCount = selectedData.events.reduce((countSoFar, item) => countSoFar + item.eventCount, 0);

  const data: IChartData[] = selectedData.events.map((item, index) => ({
    value: Math.round((item.eventCount * 100) / totalEventCount),
    legendLabel: item.topic,
    color: index < colors.length ? colors[index] : getRandomColor(),
  }));

  const onDaySelectedChange = (item: number) => {
    setSelectedDay(item);
  };

  const legendSize = {
    width: 4,
    height: 36,
  };

  return (
    <GraphCard
      possibleDays={possibleDays}
      defaultDay={defaultDay}
      title="Trending Topics"
      onDaySelectedChange={onDaySelectedChange}
      loading={loading}
      height={chartHeight}
    >
      {!loading && data.length === 0 && (
        <MessageBar messageBarType={MessageBarType.info}>No data to show for {selectedDay} day(s)</MessageBar>
      )}
      {!loading && data.length > 0 && (
        <Pie
          height={chartHeight * 3}
          width={chartHeight * 3}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: 40,
            },

            plugins: {
              legend: {
                fullSize: true,
                position: isMobileView ? "bottom" : "right",
                align: "end",
                labels: {
                  boxHeight: isMobileView ? legendSize.width : legendSize.height,
                  boxWidth: isMobileView ? legendSize.height : legendSize.width,
                  font: {
                    size: isMobileView ? 12 : 24,
                  },
                },
                display: true,
              },
            },
          }}
          data={{
            labels: data.map((item) => `${item.legendLabel} : ${item.value}%`),
            datasets: [
              {
                label: "Percent",
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
              },
            ],
          }}
        />
      )}
    </GraphCard>
  );
};
