import { PersonaPresence, PersonaSize } from "@fluentui/react";
import React from "react";

import { ModalWithFooter, PersonaWithImage, StyledText } from "src/components";
import { getDateFromDateString, getTimeFromDateString } from "src/util";

interface ISelectedEventModalUsersProps {
  selectedEventForUser?: IEventData;
  closeModal: () => void;
}

export const SelectedEventModalUsers = ({ selectedEventForUser, closeModal }: ISelectedEventModalUsersProps) => {
  return (
    <ModalWithFooter
      isOpen={selectedEventForUser !== undefined}
      onClose={closeModal}
      header={
        <div>
          <div>
            <StyledText variant="xLargePlus">{selectedEventForUser?.interestName}</StyledText>
          </div>
          <div>
            <StyledText>
              {getDateFromDateString(selectedEventForUser?.meetingTimeUtc)}{" "}
              {getTimeFromDateString(selectedEventForUser?.meetingTimeUtc)}
            </StyledText>
          </div>
        </div>
      }
      onClickSecondary={closeModal}
      maxHeight={300}
    >
      <div className="flex flex-col gap-3 ">
        {selectedEventForUser?.attendees.map((attendee) => (
          <PersonaWithImage
            size={PersonaSize.size32}
            userId={attendee.attendeeId}
            text={attendee.attendeeName}
            key={attendee.attendeeId}
            // presenc={attendee.attended ? PersonaPresence.online : PersonaPresence.offline}
            presence={PersonaPresence.away}
          />
        ))}
      </div>
    </ModalWithFooter>
  );
};
