import jwt_decode from "jwt-decode";

export const getUserIdFromAccessToken = (token: string): string => {
  const decoded: Record<string, any> = jwt_decode(token);

  return decoded.oid;
};

export const getAccessTokenHeader = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const isAccessTokenExpired = (token: string): boolean => {
  const decoded: Record<string, any> = jwt_decode(token);
  const exp = decoded.exp;
  if (!exp) return true;

  return new Date().getTime() / 1000 > +exp;
};
