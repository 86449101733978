import React from "react";

export interface IBackgroundWrapperWrapper
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  skipAddingColor?: boolean;
  backgroundColor?: string;
}

export const BackgroundWrapper = ({ skipAddingColor, backgroundColor, ...props }: IBackgroundWrapperWrapper) => {
  return (
    <div {...props} style={{ ...(props.style ?? {}), backgroundColor: skipAddingColor ? undefined : backgroundColor }}>
      {props.children}
    </div>
  );
};
