import { createContext, useCallback, useContext, useState } from "react";

import {
  apiAddInterest,
  apiArchiveIterest,
  apiGetCompanies,
  apiGetDashboardTopTopics,
  apiGetEvents,
  apiGetInterestTopics,
  apiGetInterests,
  apiGetMeetingSettings,
  apiGetUser,
  apiUpdateMeetingSettings,
  apiUpdatePreference,
} from "../services/watercooler";
import { emptyIneterest, emptyMeetingSettings, emptyUserInfo } from "../util";
import { useAuthContext } from "./Auth";

interface IWatercoolerContext {
  userInfo: WatercoolerUser;
  interests: SimpleCompanyInterest[];

  dashboardTopTopics: TopTopics[];
  getDashboardTopTopics: (forceRefresh?: boolean) => Promise<TopTopics[]>;
  getEvents: (startDate: Date, endDate: Date) => Promise<IEventData[]>;
  getCompanies: (forceRefresh?: boolean) => Promise<WatercoolerCompany[]>;
  getUserInfo: (forceRefresh?: boolean) => Promise<WatercoolerUser>;
  getInterests: (forceRefresh?: boolean) => Promise<SimpleCompanyInterest[]>;
  addInterest: (interest: string) => Promise<WatercoolerInterest>;
  updatePreference: (preference: WatercoolerUserPreference) => Promise<WatercoolerUserPreference>;
  getMeetingSettings: () => Promise<MeetingSettings>;
  updateMeetingSettings: (minAttendees: number, maxAttendees: number) => Promise<void>;
  archiveInterest: (interestId: string) => Promise<void>;
}

const watercoolerContext: IWatercoolerContext = {
  userInfo: emptyUserInfo,
  interests: [],
  dashboardTopTopics: [],
  getDashboardTopTopics: async () => [],
  getCompanies: async () => [],
  getUserInfo: async () => emptyUserInfo,
  addInterest: async () => emptyIneterest,
  updatePreference: async (item) => item,
  getInterests: async () => [],
  archiveInterest: async () => {},
  getMeetingSettings: async () => emptyMeetingSettings,
  updateMeetingSettings: async () => {},
  getEvents: async () => [],
};

const userNotLoggedInError = new Error("User is not logged in");

const WatercoolerContext = createContext(watercoolerContext);

export const WatercoolerProvider = ({ children }: IWrapperComponentProps) => {
  const [companies, setCompanies] = useState<WatercoolerCompany[]>();
  const [userInfo, setUserInfo] = useState<WatercoolerUser | undefined>();
  const [topics, setTopics] = useState<Record<string, SimpleCompanyTopic[]>>();
  const [interests, setInterests] = useState<SimpleCompanyInterest[]>([]);
  const [dashboardTopTopics, setDashboardTopTopics] = useState<TopTopics[]>([]);

  const { accessToken } = useAuthContext();

  const getCompanies = useCallback(
    async (forceRefresh?: boolean) => {
      if (!accessToken) throw userNotLoggedInError;
      if (!forceRefresh && companies) return companies;
      const companiesFrombackend = await apiGetCompanies(accessToken);
      setCompanies(companiesFrombackend);

      return companiesFrombackend;
    },
    [companies, accessToken]
  );

  const getUserInfo = useCallback(
    async (forceRefresh?: boolean) => {
      if (!forceRefresh && userInfo) return userInfo;
      if (!accessToken) throw userNotLoggedInError;

      const userInfoFromBackend = await apiGetUser(accessToken);
      setUserInfo(userInfoFromBackend);

      return userInfoFromBackend;
    },
    [accessToken, userInfo]
  );

  const getCompanyTopics = useCallback(
    async (forceRefresh?: boolean): Promise<Record<string, SimpleCompanyTopic[]>> => {
      if (!accessToken) throw userNotLoggedInError;
      if (!forceRefresh && topics) return topics;
      const topicsFromBackend = await apiGetInterestTopics(accessToken);
      setTopics(topicsFromBackend);

      return topicsFromBackend;
    },
    [topics, accessToken]
  );

  const updatePreference = async (preference: WatercoolerUserPreference) => {
    if (!accessToken) throw userNotLoggedInError;
    const res = await apiUpdatePreference(preference, accessToken);
    await getUserInfo(true);

    return res;
  };

  const getInterests = useCallback(
    async (forceRefresh?: boolean) => {
      if (!accessToken) throw userNotLoggedInError;
      if (!forceRefresh && interests.length > 0) return interests;
      const interestsFromBackend = await apiGetInterests(accessToken);

      setInterests(interestsFromBackend);

      return interestsFromBackend;
    },
    [interests, accessToken]
  );

  const addInterest = useCallback(
    async (interest) => {
      if (!accessToken) throw userNotLoggedInError;
      const newInterest = await apiAddInterest(interest, accessToken);
      setInterests([
        ...interests,
        {
          id: newInterest.id,
          name: newInterest.name,
        },
      ]);
      return newInterest;
    },
    [interests, accessToken]
  );

  const archiveInterest = useCallback(
    async (interestId: string) => {
      if (!accessToken) throw userNotLoggedInError;
      await apiArchiveIterest(interestId, accessToken);
      setInterests(interests.filter((item) => item.id !== interestId));
    },
    [accessToken, interests]
  );

  const getMeetingSettings = useCallback(async () => {
    if (!accessToken) throw userNotLoggedInError;
    const meetingSettingsFromBackend = await apiGetMeetingSettings(accessToken);
    return meetingSettingsFromBackend;
  }, [accessToken]);

  const updateMeetingSettings = useCallback(
    async (minAttendees: number, maxAttendees: number) => {
      if (!accessToken) throw userNotLoggedInError;
      await apiUpdateMeetingSettings(minAttendees, maxAttendees, accessToken);
    },
    [accessToken]
  );

  const getEvents = useCallback(
    async (startDate: Date, endDate: Date) => {
      if (!accessToken) throw userNotLoggedInError;
      const eventsFromBackend = await apiGetEvents(accessToken, startDate, endDate);

      return eventsFromBackend;
    },
    [accessToken]
  );

  const getDashboardTopTopics = useCallback(
    async (forceRefresh?: boolean) => {
      if (!accessToken) throw userNotLoggedInError;
      if (!forceRefresh && dashboardTopTopics.length > 0) return dashboardTopTopics;

      const dashboardTopTopicsFromBackend = await apiGetDashboardTopTopics(accessToken);
      setDashboardTopTopics(dashboardTopTopicsFromBackend);

      return dashboardTopTopicsFromBackend;
    },
    [dashboardTopTopics, accessToken]
  );

  const providerValue: IWatercoolerContext = {
    userInfo: userInfo ?? emptyUserInfo,
    interests,
    getCompanies,
    getUserInfo,
    updatePreference,
    getMeetingSettings,
    updateMeetingSettings,
    addInterest,
    getInterests,
    archiveInterest,
    getEvents,
    getDashboardTopTopics,
    dashboardTopTopics,
  };
  return <WatercoolerContext.Provider value={providerValue}>{children}</WatercoolerContext.Provider>;
};

export function useWatercoolerContext(): IWatercoolerContext {
  return useContext(WatercoolerContext);
}
