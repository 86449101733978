import { Link, MessageBar, MessageBarType, Spinner, SpinnerSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";

import { ErrorBar, EventCard, WhiteBackground } from "src/components";
import { useWatercoolerContext } from "src/context";
import { useIsMobileView } from "src/hooks";
import { AdminHeader } from "src/pages/Admin/AdminHeader";
import { SelectedEventModalUsers } from "src/pages/Admin/Event/SelectedEventModalUsers";
import { getDateAfter, getDateFromDateString, getTimeFromDateString } from "src/util";

import { FilterPanel } from "./FilterPanel";
import { HeaderDatePickers } from "./HeaderDatePickers";

// gets list of unique attendees from events
const getUniqueAttendeeFromEvents = (event: IEventData[]): IAttendee[] => {
  const output: IAttendee[] = [];

  event.forEach((event) => {
    event.attendees.forEach((attendee) => {
      if (output.find((item) => item.attendeeId === attendee.attendeeId) === undefined) output.push(attendee);
    });
  });

  return output;
};

export const AdminEventPage = () => {
  const [searchString, setSearchString] = useState<string>("");
  const [allEvents, setAllEvents] = useState<IEventData[]>([]);
  const [eventsToDisplay, setEventsToDisplay] = useState<IEventData[]>([]);
  const [fromDate, setFromDate] = useState<Date>(getDateAfter(-7, "days"));
  const [toDate, setToDate] = useState<Date>(getDateAfter(2, "days"));
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [selectedEventForUser, setSelectedEventForUser] = useState<IEventData>();

  // filter variables
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);

  const { getEvents } = useWatercoolerContext();

  const loadData = async () => {
    if (loading) return;
    try {
      setLoading(true);
      setSearchString("");
      const events = await getEvents(fromDate, toDate);
      setAllEvents(events);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  useEffect(() => {
    if (!searchString) setEventsToDisplay(allEvents);

    const searchFilteredEvents = (allEvents ?? []).filter((item) => {
      return item.interestName.toLowerCase().includes(searchString.toLowerCase());
    });

    const userFilteredEvents = searchFilteredEvents.filter((item) => {
      if (selectedAttendees.length === 0) return true;

      let hasMatchingUser = false;

      item.attendees.forEach((attendee) => {
        if (selectedAttendees.includes(attendee.attendeeId)) {
          hasMatchingUser = true;
          return;
        }
      });

      return hasMatchingUser;
    });

    setEventsToDisplay(userFilteredEvents);
  }, [searchString, allEvents, selectedAttendees]);

  const closeUserModal = () => {
    setSelectedEventForUser(undefined);
  };
  const isMobileView = useIsMobileView();

  const noEventsToShow = () => eventsToDisplay.length === 0;

  const onClickFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const allAttendeeList: IAttendee[] = getUniqueAttendeeFromEvents(allEvents);

  const onApplyFilter = (userIds: string[]) => {
    setSelectedAttendees(userIds);
  };

  return (
    <div className="flex w-full items-center h-full flex-col md:p-8 p-4">
      <ErrorBar error={error} />
      <div className="w-full h-full ">
        <AdminHeader
          headerText="Events"
          onSearchTextChange={setSearchString}
          searchText={searchString}
          filterCount={selectedAttendees.length}
          onClickFilter={onClickFilter}
          filterText="User"
          searchBoxPlaceholder="Search for events by topics"
        />
        <HeaderDatePickers
          loading={loading}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        {loading && (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <Spinner size={SpinnerSize.large} label="Loading events" />
          </div>
        )}
        <WhiteBackground
          skipAddingColor={isMobileView}
          className="p-8 rounded-xl gap-2  items-center justify-center"
          style={{
            boxShadow: isMobileView ? undefined : "0px 2px 4px rgba(0, 0, 0, 0.1)",
            gridTemplateColumns: noEventsToShow() ? undefined : "repeat(auto-fill, 311px)",
            display: noEventsToShow() ? undefined : "grid",
          }}
        >
          {noEventsToShow() && (
            <MessageBar messageBarType={MessageBarType.info} className="w-full">
              No Events found for selected filters
            </MessageBar>
          )}
          {eventsToDisplay.map((item) => {
            const { meetingTimeUtc, attendees, interestName, meetingId } = item;

            return (
              <EventCard
                bottomtext={interestName}
                headerText={getTimeFromDateString(meetingTimeUtc)}
                subHeaderText={getDateFromDateString(meetingTimeUtc)}
                key={meetingId}
              >
                <Link onClick={() => setSelectedEventForUser(item)}>{attendees.length} Users</Link>
              </EventCard>
            );
          })}
        </WhiteBackground>
      </div>
      <SelectedEventModalUsers selectedEventForUser={selectedEventForUser} closeModal={closeUserModal} />
      <FilterPanel
        isOpen={isFilterPanelOpen}
        onClose={() => setIsFilterPanelOpen(false)}
        attendees={allAttendeeList}
        alreadySelectedAttendees={selectedAttendees}
        onApplyFilter={onApplyFilter}
      />
    </div>
  );
};
