import React from "react";

import { BackgroundShade2, StyledText } from "src/components";

interface IInterestsProps {
  interests: string[];
}

export const getHeaderText = (interestCount: number) =>
  interestCount === 0
    ? "You're not signed up to any interest"
    : "You are currently signed up with others interested in:";

export const Interests = ({ interests }: IInterestsProps) => {
  return (
    <BackgroundShade2 className="px-8 py-6 m-8 gap-8 flex flex-col rounded-xl">
      <StyledText skipDark>{getHeaderText(interests.length)}</StyledText>

      <div className="font-bold flex flex-col gap-3">
        {interests.map((item) => (
          <StyledText key={item} skipDark>
            <b>{item}</b>
          </StyledText>
        ))}
      </div>
    </BackgroundShade2>
  );
};
