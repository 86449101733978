export const AUTHORIZATION_SCOPES = ["User.Read.All", "Calendars.ReadWrite", "Mail.Send", "MailboxSettings.Read"];

const MICROSOFT_GRAPH_VERSION = 1;
export const MICROSOFT_GRAPH_ENDPOINT = `https://graph.microsoft.com/v${MICROSOFT_GRAPH_VERSION}.0/`;

//export const MAX_INTERESTS = 3;

export const GENERIC_500_ERROR_MESSAGE = "Something went wrong, please try again later";

export const ICON_SIZE = 18;

export const MOBILE_VIEW_WIDTH = 768; //in px

export const CACHE_ACCESS_TOKEN_KEY = "Watercooler:AccessToken";
