import React from "react";

import { LogoTitle, StyledText } from "src/components/";

import { useAuthContext } from "../../context/Auth";

export const PreferenceHeader = () => {
  const { profileInfo } = useAuthContext();

  return (
    <div className="flex justify-between pb-3 flex-col md:flex-row px-8 py-6 items-center">
      <div>
        <LogoTitle />
      </div>
      <StyledText>
        Welcome <b>{profileInfo?.displayName}</b>
      </StyledText>
    </div>
  );
};
