import { Image } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { StyledPrimaryButton, StyledText } from "src/components";
import { useWatercoolerContext } from "src/context";
import { useData } from "src/hooks";
import { ROUTES } from "src/routes";

export const LandingPage = () => {
  const navigate = useNavigate();

  const { getUserInfo, userInfo } = useWatercoolerContext();
  useData(getUserInfo);

  const onClickLearnMore = () => {
    navigate(ROUTES.preferences);
  };

  if (!userInfo.isFirstLogin) navigate(ROUTES.preferences);

  return (
    <div>
    {userInfo.isFirstLogin && (
    <div className="w-full h-full items-center justify-center flex flex-col">
      <Image src="WC-02.png" width={400} />
      <StyledText variant="xLargePlus" className="text-center">
        Welcome to the future of <s>(work)</s> break
      </StyledText>
      <StyledText className="mt-2 text-center">
        WaterCooler is here to make it easier for you to socialize and connect in the workplace
      </StyledText>

      <StyledPrimaryButton
        type="notPrimary"
        text="Learn More"
        className="xl:w-[280px] mt-8"
        onClick={onClickLearnMore}
      />
    </div>
    )}
    </div>
  );
};
