import { CommandBarButton, IconButton, SearchBox } from "@fluentui/react";
import React from "react";

import { StyledDefaultButton, StyledText } from "src/components";
import { ICON_SIZE } from "src/constants";

interface IAdminHeaderProps {
  onBack?: () => void;
  headerText: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onSearchTextChange: (newText: string) => void;
  searchText: string;
  children?: React.ReactNode;
  onClickFilter?: () => void;
  filterText?: string;
  filterCount?: number;
  searchBoxPlaceholder?: string;
}

export const AdminHeader = ({
  headerText,
  onAdd,
  onDelete,
  onBack,
  onSearchTextChange,
  searchText,
  children,
  onClickFilter,
  filterText,
  filterCount,
  searchBoxPlaceholder,
}: IAdminHeaderProps) => {
  return (
    <div className="flex justify-between p-3 md:flex-row flex-col gap-3 md:gap-0">
      <div className="flex md:gap-4 md:flex-row justify-between">
        {onBack && (
          <div>
            <IconButton iconProps={{ iconName: "ChevronLeft" }} onClick={onBack} />
          </div>
        )}
        <StyledText variant="xLargePlus">{headerText}</StyledText>
        {onAdd && (
          <CommandBarButton
            text="Add"
            styles={{
              icon: {
                fontSize: ICON_SIZE,
              },
            }}
            iconProps={{ iconName: "Add" }}
            onClick={onAdd}
            onRenderText={(props) => {
              return (
                <StyledText>
                  {props?.children}
                  {props?.text}
                </StyledText>
              );
            }}
          />
        )}
        {onDelete && <CommandBarButton text="Delete" iconProps={{ iconName: "Delete" }} onClick={onDelete} />}
        <div>{children}</div>
      </div>

      <div className="flex gap-1 max-w-[500px] w-full">
        {onClickFilter && filterText && (
          <StyledDefaultButton
            onClick={onClickFilter}
            className="border-none text-primaryColor"
            iconProps={{ iconName: "FilterSolid" }}
          >
            {filterText}
            {filterCount !== undefined && ` (${filterCount})`}
          </StyledDefaultButton>
        )}
        <SearchBox
          value={searchText}
          onChange={(_, newValue) => onSearchTextChange(newValue ?? "")}
          className="flex-grow"
          placeholder={searchBoxPlaceholder}
        />
      </div>
    </div>
  );
};
