import { INavLinkGroup, Nav, Panel, PanelType } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useThemePallete } from "src/context";
import { ROUTES } from "src/routes";

interface IAdminMenuProps {
  hideMenu: () => void;
  isOpen: boolean;
}

export const AdminMenu = ({ hideMenu, isOpen }: IAdminMenuProps) => {
  const navigate = useNavigate();

  const onClickNavItem = (url: string) => {
    return (ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      ev?.preventDefault();
      navigate(url);
      hideMenu();
    };
  };

  const links: INavLinkGroup[] = [
    {
      links: [
        {
          name: "Home",
          url: ROUTES.home,
          key: "home",
          onClick: onClickNavItem(ROUTES.home),
        },
        {
          name: "Interests",
          url: ROUTES.admin.interests,
          key: "interests",
          onClick: onClickNavItem(ROUTES.admin.interests),
        },
        {
          name: "Events",
          onClick: onClickNavItem(ROUTES.admin.events),
          url: ROUTES.admin.events,
        },
        {
          name: "Dashboard",
          onClick: onClickNavItem(ROUTES.admin.dashboard),
          url: ROUTES.admin.dashboard,
        },
        {
          name: "Settings",
          onClick: onClickNavItem(ROUTES.admin.settings),
          url: ROUTES.admin.settings,
        },
      ],
    },
  ];

  const { themeLighter } = useThemePallete();

  return (
    <Panel isOpen={isOpen} onDismiss={hideMenu} type={PanelType.smallFixedNear} isLightDismiss>
      <Nav
        groups={links}
        styles={(props) => {
          if (props.isSelected) {
            return {
              link: {
                backgroundColor: themeLighter,
              },
            };
          } else return {};
        }}
      />
    </Panel>
  );
};
