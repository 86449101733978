import { DefaultButton, IconButton, Modal, Stack } from "@fluentui/react";
import React from "react";

import { StyledPrimaryButton, StyledText } from "src/components";

interface IModalWithFooterProps {
  isOpen: boolean;
  onClose: () => void;
  isBlocking?: boolean;
  children: React.ReactNode;
  header: React.ReactNode | string;
  secondaryText?: string;
  primaryText?: string;
  onClickSecondary?: () => void;
  onClickPrimary?: () => void;
  minWidth?: number;
  maxHeight?: number;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  disableAll?: boolean;
}

export const ModalWithFooter = ({
  isOpen,
  onClose,
  isBlocking,
  children,
  header,
  secondaryText,
  primaryText,
  onClickPrimary,
  onClickSecondary,
  disablePrimary,
  disableSecondary,
  disableAll,
  minWidth,
  maxHeight,
}: IModalWithFooterProps) => {
  return (
    <Modal
      isOpen={isOpen}
      isBlocking={isBlocking}
      onDismiss={onClose}
      styles={{ scrollableContent: { padding: 32, minWidth } }}
    >
      <Stack>
        <Stack horizontal styles={{ root: { justifyContent: "space-between", alignItems: "center" } }} className="pb-8">
          <Stack.Item>
            {typeof header === "string" && <StyledText variant="xLargePlus">{header}</StyledText>}
            {typeof header !== "string" && header}
          </Stack.Item>
          <Stack.Item>
            <IconButton iconProps={{ iconName: "ChromeClose" }} onClick={onClose} />
          </Stack.Item>
        </Stack>
        <Stack verticalFill className="overflow-y-auto" styles={{ root: { maxHeight } }}>
          {children}
        </Stack>
        <Stack horizontal horizontalAlign="end" className="gap-2 pt-8">
          {secondaryText && (
            <DefaultButton text={secondaryText} onClick={onClickSecondary} disabled={disableAll || disableSecondary} />
          )}
          {primaryText && (
            <StyledPrimaryButton text={primaryText} onClick={onClickPrimary} disabled={disableAll || disablePrimary} />
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
