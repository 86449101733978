import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "src/App";

export const sendErrorToLogInsights = (error: unknown, extraInfo?: any) => {
  try {
    if (!error) return;

    appInsights.trackException({
      exception: error as Error,
      error: error as Error,
      severityLevel: SeverityLevel.Error,
      properties: { ...error, ...(extraInfo ?? {}) },
    });
  } catch (err) {
    // nothing to be done
    console.log({ err });
  }
  if (!error) return;
};
