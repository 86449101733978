import { IColumn, MessageBar, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ErrorBar, StyledText, TextFieldInputModal } from "src/components";
import DeleteConfirmationModal from "src/components/Modal/DeleteConfirmationModal";
import { useAuthContext, useWatercoolerContext } from "src/context";
import { useData } from "src/hooks";
import { AdminHeader } from "src/pages";
import { ROUTES } from "src/routes";
import { apiAddTopic, apiGetTopics, sendErrorToLogInsights } from "src/services";

import { IPreviousTopicsState, getColumns } from "./columns";

export const AdminInterestPage = () => {
  const [searchString, setSearchString] = useState<string>("");
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [err, setErr] = useState<unknown>();
  const [addInterestIsOpen, setAddInterestIsOpen] = useState<boolean>(false);
  const [interestsToDisplay, setInterestsToDisplay] = useState<SimpleCompanyInterest[]>([]);
  const [interestToArchive, setInterestToArchive] = useState<SimpleCompanyInterest>();
  const [interestToAddTopic, setInterestToAddTopic] = useState<SimpleCompanyInterest>();

  const [topics, setTopics] = useState<Record<string, SimpleCompanyTopic[]>>({});
  const [loadingTopics, setLoadingTopics] = useState<Record<string, boolean>>({});
  const [expandedInterest, setExpandedInterest] = useState<Record<string, boolean>>({});

  const { addInterest, getInterests, archiveInterest, interests } = useWatercoolerContext();
  const { accessToken } = useAuthContext();

  const { loading: interestsLoading, error: interestsLoadingError } = useData(() => getInterests(true));

  const navigate = useNavigate();

  const onAdd = () => {
    setAddInterestIsOpen(true);
  };

  const onClickSaveInterst = async (addInterestName: string) => {
    if (isBusy || !addInterestName) {
      onCloseAddInterestModal();
      return;
    }

    try {
      setIsBusy(true);
      await addInterest(addInterestName);
    } catch (err) {
      sendErrorToLogInsights(err);
      setErr(err);
    } finally {
      setIsBusy(false);
    }
    onCloseAddInterestModal();
  };

  const onCloseAddInterestModal = () => {
    setAddInterestIsOpen(false);
  };

  const onCloseArchiveInterestModal = () => {
    setInterestToArchive(undefined);
  };

  const onCloseAddTopicModal = () => {
    setInterestToAddTopic(undefined);
  };

  const onArchiveInterest = async (interestId: string) => {
    if (isBusy) {
      onCloseArchiveInterestModal();
      return;
    }

    try {
      setIsBusy(true);
      await archiveInterest(interestId);
    } catch (err) {
      sendErrorToLogInsights(err);
      setErr(err);
    } finally {
      setIsBusy(false);
    }
    onCloseArchiveInterestModal();
  };

  const onAddTopicToInterest = async (topicName: string) => {
    if (isBusy || !accessToken || !interestToAddTopic) {
      onCloseAddTopicModal();
      return;
    }
    try {
      setIsBusy(true);
      await apiAddTopic(topicName, interestToAddTopic.id, accessToken);
      await getInterests(true);
    } catch (err) {
      sendErrorToLogInsights(err);
      setErr(err);
    } finally {
      setIsBusy(false);
    }
    onCloseAddTopicModal();
  };

  const goToInterestPage = (interest: SimpleCompanyInterest) => {
    navigate(`${ROUTES.admin.topics}/?interestId=${interest.id}&interestName=${interest.name}`);
  };

  useEffect(() => {
    if (!searchString) {
      setInterestsToDisplay(interests);
      return;
    }

    setInterestsToDisplay(interests.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase())));
  }, [searchString, interests]);

  // only interacts with loadingTopics and topics state
  const loadTopic = async (
    interestId: string,
    previousTopicsState?: IPreviousTopicsState
  ): Promise<IPreviousTopicsState> => {
    const { previousTopicsLoading, previousTopics } = previousTopicsState ?? {};

    if (!accessToken) return {};
    if (loadingTopics[interestId]) return {};
    const existingInterest = interests.find((item) => item.id === interestId);

    if (existingInterest) {
      setTopics({ ...topics, [interestId]: [] });
      return {};
    }

    if (existingInterest) {
      const existingTopics = previousTopics ?? topics;
      const newTopics: Record<string, SimpleCompanyTopic[]> = { ...existingTopics, [interestId]: [] };
      setTopics(newTopics);
      return {
        previousTopics: newTopics,
      };
    }

    const existingLoadingTopics = previousTopicsLoading ?? loadingTopics;
    let newLoadingTopics = { ...existingLoadingTopics, [interestId]: true };

    setLoadingTopics(newLoadingTopics);
    let newTopics = previousTopics ?? topics;

    try {
      const topicForThisInterest = await apiGetTopics(interestId, accessToken);

      const existingTopics = previousTopics ?? topics;
      newTopics = { ...existingTopics, [interestId]: topicForThisInterest };
      setTopics(newTopics);
    } catch (err) {
      sendErrorToLogInsights(err);
      setErr(err);
    } finally {
      newLoadingTopics = { ...newLoadingTopics, [interestId]: false };
      setLoadingTopics(newLoadingTopics);
    }

    return {
      previousTopics: newTopics,
      previousTopicsLoading: newLoadingTopics,
    };
  };

  const onToggleExpandInterest = async (
    interestId: string,
    expand: boolean,
    previousTopicsState?: IPreviousTopicsState
  ): Promise<IPreviousTopicsState> => {
    const { previousTopicsExpanded } = previousTopicsState ?? {};

    const existingExpandedInterest = previousTopicsExpanded ?? expandedInterest;
    const newExpandedInterest: Record<string, boolean> = { ...existingExpandedInterest, [interestId]: expand };
    setExpandedInterest(newExpandedInterest);

    let newTopics: Record<string, SimpleCompanyTopic[]> = previousTopicsState?.previousTopics ?? topics;
    let newTopicsLoading: Record<string, boolean> = previousTopicsState?.previousTopicsLoading ?? loadingTopics;

    if (expand) {
      if (!topics[interestId]) {
        const newTopicsState = await loadTopic(interestId, {
          ...previousTopicsState,
          previousTopicsExpanded: newExpandedInterest,
        });
        newTopics = newTopicsState.previousTopics ?? newTopics;
        newTopicsLoading = newTopicsState.previousTopicsLoading ?? newTopicsLoading;
      }
    }

    return {
      previousTopics: newTopics,
      previousTopicsExpanded: newExpandedInterest,
      previousTopicsLoading: newTopicsLoading,
    };
  };

  const columns: IColumn[] = getColumns({
    goToInterestPage,
    isBusy,
    onDeleteInterest: setInterestToArchive,
    onAddInterest: setInterestToAddTopic,
    onToggleExpandInterest,
    expandedInterest,
    loadingTopics,
    topics,
    interestIds: interestsToDisplay.map((item) => item.id),
  });

  return (
    <div className="flex md:p-2 w-full  h-full flex-col">
      <ErrorBar error={err ?? interestsLoadingError} />
      <div className="md:p-8 w-full h-full">
        <AdminHeader
          onAdd={onAdd}
          onSearchTextChange={setSearchString}
          searchText={searchString}
          headerText="Interests"
          searchBoxPlaceholder="Search for Interests"
        />
        <ShimmeredDetailsList
          items={interestsToDisplay}
          shimmerLines={10}
          columns={columns}
          enableShimmer={interestsLoading}
          selectionMode={SelectionMode.none}
        />
        {!interestsLoading && interestsToDisplay.length === 0 && <MessageBar>No Interests to Display</MessageBar>}
      </div>
      <TextFieldInputModal
        header="Add Interest"
        isOpen={addInterestIsOpen}
        onClickSave={onClickSaveInterst}
        onCloseModal={onCloseAddInterestModal}
        disableAll={isBusy}
      />

      <DeleteConfirmationModal
        onDelete={interestToArchive ? () => onArchiveInterest(interestToArchive?.id) : undefined}
        header="Delete Interest"
        isOpen={interestToArchive !== undefined}
        onClose={onCloseArchiveInterestModal}
        disableAll={isBusy}
      >
        Are you sure you want to delete <b>{interestToArchive?.name}</b>
      </DeleteConfirmationModal>
      <TextFieldInputModal
        header={
          <div className="flex-col flex">
            <StyledText>Add Topic</StyledText>{" "}
            <StyledText variant="small">
              <b>{interestToAddTopic?.name}</b>
            </StyledText>
          </div>
        }
        isOpen={interestToAddTopic !== undefined}
        onClickSave={onAddTopicToInterest}
        onCloseModal={onCloseAddTopicModal}
        disableAll={isBusy}
      />
    </div>
  );
};
