import React from "react";

import { useDarkMode, useThemePallete } from "src/context";

import { BackgroundWrapper } from "./BackgroundWrapper";

export interface IBackgroundProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  skipAddingColor?: boolean;
}

export const WhiteBackground = (props: IBackgroundProps) => {
  const { backgroundColor, foregroundColorShade2 } = useThemePallete();
  const isDarkMode = useDarkMode();
  const { skipAddingColor } = props;

  const backgroundColorToUse = skipAddingColor ? undefined : isDarkMode ? foregroundColorShade2 : backgroundColor;

  return (
    <BackgroundWrapper {...props} skipAddingColor={skipAddingColor} backgroundColor={backgroundColorToUse}>
      {props.children}
    </BackgroundWrapper>
  );
};
